<template>
  <div class="relative h-8 w-8" @click="onClick">
    <div
      v-if="server && cantidadItemsCookie && +cantidadItemsCookie > 0"
      class="absolute -right-1 top-0.5 h-4 w-4 rounded-full bg-red-500 text-center text-xs text-white"
    >
      {{ cantidadItemsCookie }}
    </div>
    <div
      v-else-if="!server && $cantidadItems > 0"
      class="absolute -right-1 top-0.5 h-4 w-4 rounded-full bg-red-500 text-center text-xs text-white"
    >
      {{ $cantidadItems }}
    </div>
    <CarritoIcon
      class="h-8 w-8 cursor-pointer"
      stroke="#297E36"
      color="#297E36"
    />
  </div>
</template>
<script setup lang="ts">
  import { openCart, cantidadItems } from "@stores/carro";
  import { useStore } from "@nanostores/vue";
  import { onMounted, ref } from "@vue/runtime-core";
  import { viewCart } from "@lib/eventos";
  import { itemsCarro } from "@stores/carro";
  import CarritoIcon from "@components/iconos/CarritoIcon.vue";
  const $itemsCarro = useStore(itemsCarro);
  const $cantidadItems = useStore(cantidadItems);
  const server = ref(true);

  interface Props {
    cantidadItemsCookie?: string | undefined;
  }
  defineProps<Props>();

  const onClick = () => {
    viewCart($itemsCarro.value);
    openCart();
  };

  onMounted(() => {
    server.value = false;
  });
</script>
