<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" fill="none">
    <path
      d="M4.24783 13.0781H23.7259C24.2911 13.0781 24.7105 13.6058 24.583 14.1575L22.0189 25.3183C21.9266 25.7182 21.5719 26.0015 21.1618 26.0015H6.847C6.43875 26.0015 6.08408 25.72 5.98987 25.3201L3.39255 14.1594C3.26324 13.6058 3.68256 13.0781 4.24968 13.0781H4.24783Z"
      :stroke="color"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M25.383 9.42188H2.59281C2.04494 9.42188 1.60083 9.86701 1.60083 10.4161V12.0825C1.60083 12.6316 2.04494 13.0767 2.59281 13.0767H25.383C25.9309 13.0767 26.375 12.6316 26.375 12.0825V10.4161C26.375 9.86701 25.9309 9.42188 25.383 9.42188Z"
      :stroke="color"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.81253 9.41889L12.6814 2"
      :stroke="color"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M21.1875 9.41889L15.3186 2"
      :stroke="color"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.0202 21.5567C10.131 21.9504 10.5393 22.1794 10.932 22.0683C11.3248 21.9572 11.5533 21.5481 11.4425 21.1544L10.2468 16.9082C10.136 16.5146 9.72775 16.2855 9.33499 16.3966C8.94224 16.5077 8.7137 16.9169 8.82455 17.3105L10.0202 21.5567Z"
      :fill="color"
      :stroke="color"
      stroke-width="0.25"
      stroke-miterlimit="10"
    />
    <path
      d="M13.1674 21.4547C13.1674 21.8637 13.4982 22.1953 13.9063 22.1953C14.3144 22.1953 14.6452 21.8637 14.6452 21.4547V17.0426C14.6452 16.6336 14.3144 16.302 13.9063 16.302C13.4982 16.302 13.1674 16.6336 13.1674 17.0426V21.4547Z"
      :fill="color"
      :stroke="color"
      stroke-width="0.25"
      stroke-miterlimit="10"
    />
    <path
      d="M16.1532 20.9635C16.0064 21.3452 16.196 21.7738 16.5767 21.921C16.9575 22.0682 17.3852 21.8781 17.5321 21.4965L19.1161 17.38C19.2629 16.9983 19.0733 16.5697 18.6926 16.4225C18.3118 16.2753 17.8841 16.4654 17.7372 16.847L16.1532 20.9635Z"
      :fill="color"
      :stroke="color"
      stroke-width="0.25"
      stroke-miterlimit="10"
    />
  </svg>
</template>

<script setup lang="ts">
  interface Props {
    color?: string;
  }
  withDefaults(defineProps<Props>(), {
    color: "#000",
  });
</script>
